import React from 'react'

const createSlug = title => {
  return title.toLowerCase().split(' ').join('-').split(':').join('').split('(').join('').split(')').join('').split('/').join('');
}

const AutoLinkHeader = ({ is: Component,  fileAbsolutePath, ...props }) => {
  const path = fileAbsolutePath.split("/");
  const title = typeof props.children === "string" ? props.children : path[path.length - 1];
  return (
    <Component id={ createSlug( title ) } {...props} />
  );
}

AutoLinkHeader.defaultProps = { is: "h2" };

export default AutoLinkHeader;
