import React from 'react';
import { graphql } from 'gatsby';
import { Anchor, Row, Col, Divider } from 'antd';
import SiteLayout from '../components/SiteLayout';
import { Loading } from '../components/Loading/Loading';
import Note from '../components/Note';
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from '@mdx-js/react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightEighties } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import AutoLinkHeader from '../components/AutolinkHeader';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import { useAuth0 } from '@auth0/auth0-react';


const { Link: AnchorLink } = Anchor;

const CodeSyntaxHighlighter = (props) => {
  const { children, className } = props;
  const language = className && className.includes('language-') ? className.replace('language-', '') : 'javascript';
  return <SyntaxHighlighter language={language} style={tomorrowNightEighties}>{children}</SyntaxHighlighter>;
};

const CodeReactEditor = ({ children, ...props }) => {
  return (
    <LiveProvider code={children}>
      <LiveEditor />
      <LiveError />
      <LivePreview />
    </LiveProvider>
  );
}

const getCodeEditor = (location) => {
  // if code inserted is a React component we use a react editor otherwise just highlight it
  if (location.pathname.includes("components/")) {
    return CodeReactEditor;
  } else {
    return CodeSyntaxHighlighter;
  }
};

const DocTemplate = ({data, location}) => {
    const sideBar = location.pathname.includes('/tutorials/') ? "tutorials" : "documentation";
    const tableOfContents = (data.mdx.frontmatter.tableOfContents !== false);
    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect
    } = useAuth0();

    if (isLoading) return <Loading/>;

    return (
        <SiteLayout location={location} sideBar={sideBar}>
            { isAuthenticated ? (
                <Row type='flex' justify='space-between'>
                    <Col xs={24} lg={tableOfContents?16:(16+8)} xl={tableOfContents?18:(18+6)}>
                        <div className="content" style={{ padding: '0px 48px 0 35px' }}>
                            <h1>{data.mdx.frontmatter.title}</h1>
                            <Divider />
                            <MDXProvider
                                components={{
                                    //code: props => <CodeSyntaxHighlighter {...props} />,
                                    code: getCodeEditor(location),
                                    Note: Note,
                                    h1: props => <AutoLinkHeader is="h1" {...props} fileAbsolutePath={data.mdx.fileAbsolutePath} />,
                                    h2: props => <AutoLinkHeader is="h2" {...props} fileAbsolutePath={data.mdx.fileAbsolutePath} />,
                                    h3: props => <AutoLinkHeader is="h3" {...props} fileAbsolutePath={data.mdx.fileAbsolutePath} />,
                                    h4: props => <AutoLinkHeader is="h4" {...props} fileAbsolutePath={data.mdx.fileAbsolutePath} />,
                                    h5: props => <AutoLinkHeader is="h4" {...props} fileAbsolutePath={data.mdx.fileAbsolutePath} />,
                                    h6: props => <AutoLinkHeader is="h4" {...props} fileAbsolutePath={data.mdx.fileAbsolutePath} />
                                }}
                            >
                                <MDXRenderer>
                                    {data.mdx.body}
                                </MDXRenderer>
                            </MDXProvider>
                            {/*  <Divider />
                                <a href={`https://bitbucket.strands.com/projects/ENG/repos/developer-portal-strands/browse/src/${data.mdx.fields.path}`} target="_blank" rel="noopener noreferrer" >
                                    <Button icon='github' type='dashed'>Edit this page</Button>
                                </a> */}
                        </div>
                    </Col>
                    {tableOfContents && (<Col xs={0} lg={8} xl={6}>
                        {
                            data.mdx.tableOfContents.items && data.mdx.tableOfContents.items.length ?
                                <Anchor
                                    offsetTop={85}>
                                    {data.mdx.tableOfContents.items && data.mdx.tableOfContents.items.map( item => {
                                        return (<AnchorLink key={item.url} href={item.url} title={item.title} >{
                                            item.items && item.items.map( childItem => {
                                                return (
                                                    <AnchorLink key={childItem.url} href={childItem.url} title={childItem.title} >
                                                        {
                                                            childItem.items && childItem.items.map( grandChildItem => {
                                                                return <AnchorLink key={grandChildItem.url} href={grandChildItem.url} title={grandChildItem.title} />
                                                            })
                                                        }
                                                    </AnchorLink>
                                                )
                                            })
                                        }</AnchorLink>)
                                    })}
                                </Anchor>
                                : null
                        }
                    </Col>)}
                </Row>
            ) : (
                <>
                You should <a onClick={loginWithRedirect}>log in</a> to see restricted
                content
                </>
            )}
        </SiteLayout>);
}

export default DocTemplate

export const pageQuery = graphql`
query GET_DOC_PAGE( $id: String! $fileAbsolutePath: String ) {
  mdx(id: {eq: $id}) {
    id
    timeToRead
    fileAbsolutePath
    frontmatter {
      title
      description
      tableOfContents
    }
    tableOfContents
    fields{
      path
    }
    wordCount {
      words
      sentences
      paragraphs
    }
    body
  }
  file(absolutePath: {eq: $fileAbsolutePath}) {
    id
    birthTime(formatString:"MMMM DD, YYYY")
    modifiedTime
    changeTime
  }
}
`;
